/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Need great API docs, but not ready to hire your first full-time writer?\nDon't want to dedicate developer resources to maintaining your docs-as-code system?\nOur full-service REST API documentation package includes everything you need to get your documentation written and published.\nIt includes:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Custom API documentation", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Quickstart guide"), "\n", React.createElement(_components.li, null, "OpenAPI specification"), "\n", React.createElement(_components.li, null, "Custom Postman collection"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Web hosting", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "A fully hosted documentation web page"), "\n", React.createElement(_components.li, null, "CI/CD"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "API governance"), "\n", React.createElement(_components.li, null, "And more!"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/full-service-api-docs.pdf"
  }, "Download full package details")), "\n", React.createElement(_components.p, null, "Package customization is available upon request."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
